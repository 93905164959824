<template>
    <div class="Section" :style="style" :class="{ 'has-border': hasBorder }">
        <h3>{{ title }}</h3>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        hasBorder: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        style() {
            return '';
        },
    },
};
</script>

<style lang="scss">
.Section {
    padding: 0 0 15px;
    position: relative;

    &:first-of-type {
        padding: 0 0 30px;
    }

    &.hasBorder {
        padding: 30px 0;
        border-bottom: solid 2px rgba(0, 0, 0, 0.1);

        &:last-of-type {
            border-bottom: none;
        }
    }

    h3 {
        font-weight: 700;
        margin: 20px 0;
    }
}
</style>
